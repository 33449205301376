import React from "react"
import { MediaContextProvider } from "./src/utils/Media"
import SimpleReactLightbox from "simple-react-lightbox"

export const wrapRootElement = ({ element }) => {
  return (
    <MediaContextProvider>
      <SimpleReactLightbox>{element}</SimpleReactLightbox>
    </MediaContextProvider>
  )
}
